interface AppConfig {
    baseUrl: string;
    botName: string;
}

let config: AppConfig = {
    botName: '',
    baseUrl: ''
}

if (process.env.REACT_APP_ENV === 'local') {
    config = {
        botName: 'maniga_bot',
        baseUrl: 'https://localhost:49327',
    }
}
if (process.env.REACT_APP_ENV === 'dev') {
    config = {
        botName: 'maniga_bot',
        baseUrl: 'https://apidev.uravni.tel',
    }
}
if (process.env.REACT_APP_ENV === 'stage') {
    config = {
        botName: 'onside_bot',
        baseUrl: 'https://api.onsidebot.com',
    }
}
if (process.env.REACT_APP_ENV === 'prod') {
    config = {
        botName: 'uravnitel_bot',
        baseUrl: 'https://api.uravni.tel',
    }
}

export default config